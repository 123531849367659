const CommentIcon = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.0571 11.7071H9.83982L9.65616 11.8233L5.20714 14.638V12.4571V11.7071H4.45714H2.97143C2.21531 11.7071 1.67756 11.474 1.33033 11.1268C0.983102 10.7796 0.75 10.2418 0.75 9.48571V2.97143C0.75 2.21531 0.983102 1.67756 1.33033 1.33033C1.67756 0.983102 2.21531 0.75 2.97143 0.75H13.0286C13.7847 0.75 14.3224 0.983102 14.6697 1.33033C15.0169 1.67756 15.25 2.21531 15.25 2.97143V9.48571C15.25 10.2418 15.0169 10.7796 14.6697 11.1268C14.3224 11.474 13.7847 11.7071 13.0286 11.7071H10.0571Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
    </svg>
  );
};

export default CommentIcon;
