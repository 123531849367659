import classNames from 'classnames';
import { AnimatePresence } from 'framer-motion';
import { FC, useState } from 'react';
import LogInModal from '~/components/LogInModal/LogInModal';
import { useUser } from '~/context/UserContext';
import CommentIcon from '~/icons/CommentIcon';
import DownVote from '~/icons/DownVote';
import UpVote from '~/icons/UpVote';
import { Tag, TagStatistics, VotesMeta } from '~/types';
import { VoteType } from '~/types/enums';

import { Link, useFetcher } from '@remix-run/react';

import styles from './ArticleActions.module.scss';

interface Props {
  articleId: number;
  votesMeta: VotesMeta;
  commentsCount: number;
  tags: Tag[];
  tagStats?: TagStatistics;
  slug?: string;
}

const ArticleActions: FC<Props> = ({
  articleId,
  votesMeta,
  commentsCount,
  slug,
  tagStats,
  tags,
}) => {
  const { user } = useUser();
  const favoriteTagsIds = user?.favorite_tags.map((tag) => tag.id);
  const articleTagsIds = tags.map((tag) => tag.id);
  const hasFavoriteTag = favoriteTagsIds?.some((favoriteTagId) =>
    articleTagsIds.includes(favoriteTagId),
  );

  const formulaMultiplier = hasFavoriteTag ? 2 : 1;

  const getStartingVoteSum = () => {
    if (tagStats?.updated_formula_result) {
      return Math.round(
        parseFloat(tagStats.updated_formula_result) * formulaMultiplier +
          votesMeta.vote_sum,
      );
    }
    if (tagStats?.formula_result) {
      return Math.round(
        parseFloat(tagStats.formula_result) * formulaMultiplier +
          votesMeta.vote_sum,
      );
    }
    if (tagStats?.formula_result_v2) {
      return Math.round(
        parseFloat(tagStats.formula_result_v2) * formulaMultiplier +
          votesMeta.vote_sum,
      );
    }
    return votesMeta.vote_sum;
  };
  const [isUpvote, setIsUpvote] = useState(
    votesMeta.current_user_vote_type === VoteType.Up,
  );

  const [isDownvote, setIsDownvote] = useState(
    votesMeta.current_user_vote_type === VoteType.Down,
  );

  const [showModal, setShowModal] = useState(false);

  const [voteCount, setVoteCount] = useState(getStartingVoteSum());

  const fetcher = useFetcher();

  const revalidate = () => {
    // Explicitly re-fetch the current route loader
    fetcher.load(window.location.pathname);
  };

  const onVote = async (voteType: VoteType) => {
    if (!user) {
      return setShowModal(true);
    }

    try {
      setVoteCount((prev) => {
        if (voteType === VoteType.Up) {
          if (isUpvote) {
            return prev - 1;
          } else if (isDownvote) {
            return prev + 2;
          } else {
            return prev + 1;
          }
        } else if (voteType === VoteType.Down) {
          if (isUpvote) {
            return prev - 2;
          } else if (isDownvote) {
            return prev + 1;
          } else {
            return prev - 1;
          }
        }
        return prev;
      });

      if (voteType === VoteType.Up) {
        setIsUpvote(!isUpvote);
        isDownvote && setIsDownvote(false);
      }

      if (voteType === VoteType.Down) {
        setIsDownvote(!isDownvote);
        isUpvote && setIsUpvote(false);
      }

      const response = await fetch(`/vote/${articleId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ voteType }),
      });

      await response.json();

      revalidate();
    } catch (e) {
      return;
    }
  };

  return (
    <div className={classNames(styles.actions)}>
      <div className={styles.votes}>
        <div className={styles.votesHolder}>
          <button
            onClick={(e) => {
              e.preventDefault();
              onVote(VoteType.Up);
            }}
            className={classNames(styles.voteButton, styles.voteButtonUp, {
              [styles.active]: isUpvote,
            })}
            aria-label='Upvote'
          >
            <UpVote />
            <span className={styles.votesCount}>Vote {voteCount}</span>
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              onVote(VoteType.Down);
            }}
            className={classNames(styles.voteButton, styles.voteButtonDown, {
              [styles.active]: isDownvote,
            })}
            aria-label='Downvote'
          >
            <DownVote />
          </button>
        </div>
        <Link
          to={slug ? `/article/${slug}#comments` : '#comments'}
          className={styles.commentButton}
          aria-label='Comment'
        >
          <CommentIcon />
          {commentsCount}
        </Link>
      </div>
      <AnimatePresence>
        {showModal && <LogInModal onClose={() => setShowModal(false)} />}
      </AnimatePresence>
    </div>
  );
};

export default ArticleActions;
